
import React from "react";
//import { render } from "@testing-library/react";

import '../styles/Tracking.css'
import logoHagemsa from "../img/hagemsa.png";

class Tracking extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ordencompra: props.datostracking[0].ordencompra,
            citem: props.datostracking[0].citem,
            cnomprod: props.datostracking[0].cnomprod,
            cgrcliente: props.datostracking[0].cgrcliente,
            ncantidad: props.datostracking[0].ncantidad,
            iddespacho: props.datostracking[0].iddespacho,
            cembarque: props.datostracking[0].cembarque,
            dfecharecepcion: props.datostracking[0].dfecharecepcion,
            dfechaenvio: props.datostracking[0].dfechaenvio,
            despachado: (props.datostracking[0].iddespacho ? 'step completed' : 'step'),
            transito: (props.datostracking[0].iddespacho ? 'step completed' : 'step'),
            almacen: props.datostracking[0].almacen,
            dfechaeta: props.datostracking[0].dfechaeta ? props.datostracking[0].dfechaeta : 'TBD',
            dfecharecepcionmina: (props.datostracking[0].idrecepcionadomina ? props.datostracking[0].dfecharecepcionmina : props.datostracking[0].dfechaeta),
            textofecharecepcionmina: (props.datostracking[0].idrecepcionadomina ? 'Entrega Mina' : 'ETA Mina'),
            cobservaciones: props.datostracking[0].cobservaciones,
            estado: props.datostracking[0].estado,
            recepcionadomina: (props.datostracking[0].idrecepcionadomina ? 'step completed' : 'step'),
            adjunto: props.datostracking[0].adjunto,
            ligadjuntos: props.datostracking[0].ligadjuntos + "damedocumentoxidrecepcion?idrecepcion=" + props.datostracking[0].idrecepcionadomina

        };

    }




    onBuscar = () => {
        this.props.setVentana(1);
    }

    render() {
        return (
            <div id="tablabusquedaform" className="main_container">
                <div><center>
                    <img src={logoHagemsa} alt='hagemsa' className='img-home' /></center><br />
                    <h4 className="titulo2"><center>HgTrack</center></h4>
                </div>
                <center>
                    <div id="button" className="row m-2" >
                        <button className="btn btn-danger" onClick={this.onBuscar} >Regresar</button>
                    </div>
                </center>

                <div className="container padding-bottom-3x mb-1">
                    <div className="card mb-3">
                        
                        <div className="row">
                            <div className="col-6 flex-derecha"><b>Orden Compra N°:</b></div>
                            <div className="col-6 ">{this.state.ordencompra}</div>
                        </div>
                        
                        <div className="row">
                            <div className="col-6 flex-derecha"><b>Item:</b> </div>
                            <div className="col-6">{this.state.citem}</div>
                        </div>
                        <div className="row">
                            <div className="col-6 flex-derecha"><b> Descripción:</b> </div>
                            <div className="col-6">{this.state.cnomprod}</div>
                        </div>
                        <div className="row">
                            <div className="col-6 flex-derecha"> <b>Cantidad:</b> </div>
                            <div className="col-6">{this.state.ncantidad}</div>
                        </div>
                        <div className="row">
                            <div className="col-6 flex-derecha"> <b>Guia Aaq:</b> </div>
                            <div className="col-6">{this.state.cgrcliente}</div>
                        </div>
                        



                        <hr />
                        <div className="card-body">
                            <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                                <div className="step completed">
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-note2"></i></div>
                                    </div>
                                    <h4 className="step-title">Recepcionado ({this.state.almacen}) <br></br> Fecha {this.state.dfecharecepcion}</h4>
                                </div>

                                <div className={this.state.despachado} >
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-medal"></i></div>
                                    </div>
                                    <h4 className="step-title">Despachado<br /> Fecha: {this.state.dfechaenvio}</h4>


                                </div>
                                <div className={this.state.transito}>
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-car"></i></div>
                                    </div>
                                    <h4 className="step-title">Transito<br /> Fecha: {this.state.dfechaenvio}</h4>
                                </div>
                                <div className={this.state.recepcionadomina}>
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-home"></i></div>
                                    </div>
                                    <h4 className="step-title">{this.state.textofecharecepcionmina}<br /> Fecha: {this.state.dfecharecepcionmina} <br /> {this.state.cembarque} </h4>
                                    <h4 className="step-title">Ref: {this.state.cobservaciones}</h4>
                                    <h4 className="step-title">Descarga Adjuntos:{
                                        this.state.adjunto ? (
                                            <a href={this.state.ligadjuntos} >Descargar</a>
                                        ) : (<></>)
                                    }
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div >





        )
    }
}
export default Tracking;