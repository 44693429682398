import React from "react";

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
//import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useState } from 'react'

import '../styles/TablaBusqueda.css';
import logoHagemsa from "../img/hagemsa.png";
//import { setSelectionRange } from "@testing-library/user-event/dist/utils";

export default function TablaBusqueda(props) {
    // const {setVentana}=props;
    const setVentana = props.setVentana;
    // const setIdmovimiento = props.setIdmovimiento;
    const filtrobusqueda = props.filtrobusqueda;
    const setFiltrobusqueda = props.setFiltrobusqueda;

    const setDatosTracking = props.setDatosTracking;

    const setDatosSimulacion = props.setDatosSimulacion;

    let ligadjuntos = props.linkadjuntos



    const resultadobusqueda = props.resultadobusqueda;
    const setResultadoBusqueda = props.setResultadoBusqueda;
    //const [resultadobusqueda, setResultadoBusqueda] = useState("");

    const [ordencompra, setOrdenCompra] = useState(filtrobusqueda[0].oc);
    const [item, setItem] = useState(filtrobusqueda[0].item);
    const [cgrcliente, setGrCliente] = useState(filtrobusqueda[0].gr);

    const [mensajeadvertencia, setMensajeAdvertencia] = useState("");
    const [mensajeencontroinformacion, setMensajeEnControInformacion] = useState("");

    const onLeeOc = e => {
        setOrdenCompra(e.target.value);
        setMensajeAdvertencia("");
        let filtro1 = [...filtrobusqueda];
        filtro1[0].oc = e.target.value;

        setFiltrobusqueda(filtro1);


    };
    const onLeeItem = e => {
        setItem(e.target.value); setMensajeAdvertencia("")
        let filtro1 = [...filtrobusqueda];
        filtro1[0].item = e.target.value;

        setFiltrobusqueda(filtro1);

    };

    const onLeeGr = e => {
        setGrCliente(e.target.value); setMensajeAdvertencia("")
        let filtro1 = [...filtrobusqueda];
        filtro1[0].gr = e.target.value;

        setFiltrobusqueda(filtro1);
    };

    const onBuscar = async () => {

        setResultadoBusqueda(null);
        setMensajeAdvertencia("");

        var validacion = "";

        validacion = validacion + (ordencompra === '' ? "0" : "1");
        validacion = validacion + (item === '' ? "0" : "1");
        validacion = validacion + (cgrcliente === '' ? "0" : "1");


        if (validacion === '000' || validacion === '010' || validacion === '011 ') {  //| | validacion === '101'
            if (validacion === '010') {
                setMensajeAdvertencia("Debe elegir al menos una OC");
            }
            else if (validacion === '011') {
                setMensajeAdvertencia("Debe elegir al menos una OC");
            }
            /*else if(validacion=== '101'){
                setMensajeAdvertencia("Debe elegir al menos un Item");
            }*/
            else { setMensajeAdvertencia("Debe elegir al menos un filtro"); }

        }
        else {
            setMensajeEnControInformacion('....espere un momento por favor');

            //const fetch = require('node-fetch');
            //const https = require('https');
            //const httpsAgent = new https.Agent({
            //    rejectUnauthorized: false,
            //});
           
            const parametrosFetch={
                method: 'GET',
                headers: {"Content-Type":"application/json"},
                agent: {"rejectUnauthorized": "false"},
            };
            const tmpip = await fetch(process.env.REACT_APP_URL_API_EXTRANET + "fuenteextranet.php?p=dameip");

            const ip = await tmpip.json();
            let api = "";
           // api = await fetch(process.env.REACT_APP_URL_HGAPI + "tracking?cnumoc=" + ordencompra + "&citem=" + item + "&cgrcliente=" + cgrcliente,parametrosFetch);
           api = await fetch(process.env.REACT_APP_URL_API_EXTRANET + "fuenteextranet.php?p=seguimientoalison&cnumoc=" + ordencompra + "&citem=" + item + "&cgrcliente=" + cgrcliente);
            const resultado = await api.json();
            setMensajeEnControInformacion(resultado.length === 0 ? 'Los filtros no producen resultados ' : '');
            setResultadoBusqueda(resultado);

        }


    }
    const onSeguimiento = e => {


        // setIdmovimiento(e.target.id);
        let datotmp = [...resultadobusqueda];


        let tmp = [{
            ordencompra: datotmp[e.target.id].cnumeroorden,
            citem: datotmp[e.target.id].citem,
            cnomprod: datotmp[e.target.id].cnomprod,
            cgrcliente: datotmp[e.target.id].cgrcliente,
            ncantidad: datotmp[e.target.id].ncantidad,
            iddespacho: datotmp[e.target.id].iddespacho,
            cembarque: datotmp[e.target.id].cembarque,
            dfecharecepcion: datotmp[e.target.id].dfecha,
            dfechaenvio: datotmp[e.target.id].dfechaenvio,
            iddespachado: datotmp[e.target.id].iddespacho,

            almacen: datotmp[e.target.id].almacen,
            dfecharecepcionmina: datotmp[e.target.id].dfecharecepcionmina,
            dfechaeta: datotmp[e.target.id].dfechaeta,
            cobservaciones: datotmp[e.target.id].cobservaciones,
            estado: datotmp[e.target.id].estado,
            idrecepcionadomina: datotmp[e.target.id].idrecepcionmina,
            adjunto: datotmp[e.target.id].adjunto,
            ligadjuntos: ligadjuntos
        }]

        setDatosTracking(tmp);
        setVentana(2);
    }


    const onSimular = e => {

        let datotmp = [...resultadobusqueda]

        var fecharecepciontexto = datotmp[e.target.id].dfecha;
        let afecha = fecharecepciontexto.split("/")
        let fecharecepcionobj = new Date(afecha[1] + "/" + afecha[0] + "/" + afecha[2])
        let fechatmp = new Date(afecha[1] + "/" + afecha[0] + "/" + afecha[2]);
        var fechadespacho = ''
        var fechallegada = ''

        if (datotmp[e.target.id].almacen === 'LIMA') {
            fecharecepcionobj.setDate(fecharecepcionobj.getDate() + 7)
            fechatmp.setDate(fechatmp.getDate() + 10)

            fechadespacho = fecharecepcionobj.getDate() + "/" + (fecharecepcionobj.getMonth() + 1) + "/" + fecharecepcionobj.getFullYear();
            fechallegada = fechatmp.getDate() + "/" + (fechatmp.getMonth() + 1) + "/" + fechatmp.getFullYear();
        }
        else if (datotmp[e.target.id].almacen === 'AREQUIPA') {


            fecharecepcionobj.setDate(fecharecepcionobj.getDate() + 7)
            fechatmp.setDate(fechatmp.getDate() + 9)
            fechadespacho = fecharecepcionobj.getDate() + "/" + (fecharecepcionobj.getMonth() + 1) + "/" + fecharecepcionobj.getFullYear();
            fechallegada = fechatmp.getDate() + "/" + (fechatmp.getMonth() + 1) + "/" + fechatmp.getFullYear();
        }
        else if (datotmp[e.target.id].almacen === 'MOQUEGUA') {


            fecharecepcionobj.setDate(fecharecepcionobj.getDate() + 7)
            fechatmp.setDate(fechatmp.getDate() + 8)
            fechadespacho = fecharecepcionobj.getDate() + "/" + (fecharecepcionobj.getMonth() + 1) + "/" + fecharecepcionobj.getFullYear();
            fechallegada = fechatmp.getDate() + "/" + (fechatmp.getMonth() + 1) + "/" + fechatmp.getFullYear();
        }





        let tmp = [{
            oc: datotmp[e.target.id].cnumeroorden,
            item: datotmp[e.target.id].citem,
            frecepcion: datotmp[e.target.id].dfecha,
            fdespacho: fechadespacho,
            fllegada: fechallegada,
            almacen: datotmp[e.target.id].almacen,
            descripcion: datotmp[e.target.id].cnomprod,
            cantidad: datotmp[e.target.id].ncantidad,
        }]

        setDatosSimulacion(tmp)

        setVentana(3);
    }

    return (

        <div id="" className="container">
            <div><center>
                <img src={logoHagemsa} alt='hagemsa' className='img-home' /></center><br />
                <h4 className="titulo2"><center>HgTrack</center></h4>
            </div>
            <div className="row">
                <div className="col-md-4">
                <label >Ingrese Oc </label>
                <input type="text" className="form-control " placeholder="Ingrese OC" aria-label="" aria-describedby="button-addon1" value={ordencompra} onChange={onLeeOc} />
                </div>
            
                <div className="col-md-4">
                <label >Ingrese Item </label>
                <input type="text" className="form-control" placeholder="Ingrese Item" aria-label="" aria-describedby="button-addon" value={item} onChange={onLeeItem} />
                </div>

                <div className="col-md-4">
                <label >Ingrese GR </label>
                <input type="text" className="form-control" placeholder="Ingrese Gr" aria-label="" aria-describedby="button-addon" value={cgrcliente} onChange={onLeeGr} />
                </div>
            </div>

            
                <div><center><span className="mensajeerror">{mensajeadvertencia}</span></center></div>
                <br></br>
                
                    <div className="row">

                    <div className="offset-md-2 col-md-8">
                    <div className="row m-1">
                    <button className="btn  btn-danger" onClick={onBuscar} >Buscar</button>
                    </div>
                    </div></div>
                
            
            <br></br>

            <div>
                <Table className="table">
                    <Thead>
                        <Tr>
                            <Th>ALMACEN</Th>
                            <Th>OC</Th>
                            <Th>ITEM</Th>
                            <Th>DESCRIPCIÓN</Th>
                            <Th>CANTIDAD</Th>
                            <Th>GR</Th>
                            <Th>FECHA RECEPCIÓN</Th>
                            <Th>ESTADO</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {

                            resultadobusqueda ? (

                                resultadobusqueda.map((value, index) => (

                                    <Tr id={value.idmovimiento} >
                                        <Td >{value.almacen}</Td>
                                        <Td >{value.cnumeroorden}</Td>
                                        <Td>{value.citem}</Td>
                                        <Td>{value.cnomprod}</Td>
                                        <Td>{value.ncantidad}</Td>
                                        <Td>{value.cgrcliente}</Td>
                                        <Td>{value.dfecha}</Td>
                                        <Td>{value.estado}</Td>
                                        <Td>{value.iddespacho ? (<div className="row bottonseguimiento"><button className="btn btn-warning" id={index} onClick={onSeguimiento}>Ver</button> </div>) : (<div className="row bottonseguimiento"><button className="btn btn-primary" id={index} onClick={onSimular}>Simular</button> </div>)} </Td>
                                    </Tr>
                                ))

                            ) : (<></>)
                        }

                    </Tbody>
                </Table></div>
            <div>
                <center><span className="mensajeerror">{mensajeencontroinformacion}</span></center>
            </div>
            <div>
                <h3 id="pieTitle">@Transportes Hagemsa SAC {props.anio} </h3>
            </div>
        </div>

    );
}


