import React from 'react';
import LoginForm from './componentes/login';
import TablaBusqueda from './componentes/TablaBusqueda';
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Tracking  from './componentes/Tracking';
import TrackingSimulado  from './componentes/TrackingSimulado';

function App() {


  const [ventana, setVentana] = useState(0);
  const [idmovimiento, setIdmovimiento] = useState(null);
  const [filtrobusqueda,setFiltrobusqueda]= useState([{oc:'',item:'',gr:''}]);
  const [datossimulacion,setDatosSimulacion]=useState([{oc:'',item:'',frecepcion:'',fdespacho:'',fllegada:'',almacen:'',descripcion:'',cantidad:''}]);
  const [datostracking,setDatosTracking]=useState([{}]);
  const [resultadobusqueda,setResultadoBusqueda] = useState(null);
  const [linkadjuntos,setLinkAdjuntos] = useState(null);


  if (ventana === 0) {
    return <LoginForm setVentana={setVentana} setLinkAdjuntos={setLinkAdjuntos} />;
  }
  else if (ventana === 1) {
    return <TablaBusqueda   linkadjuntos={linkadjuntos}   setDatosTracking={setDatosTracking} resultadobusqueda={resultadobusqueda} setResultadoBusqueda={setResultadoBusqueda}  setVentana={setVentana} setIdmovimiento={setIdmovimiento} filtrobusqueda={filtrobusqueda} setFiltrobusqueda={setFiltrobusqueda} setDatosSimulacion={setDatosSimulacion} />;
  }
  else if (ventana === 2) {
   return <Tracking  setVentana={setVentana} idmovimiento={idmovimiento} datostracking ={datostracking} />;
  }
  else if (ventana === 3) {
    return <TrackingSimulado  setVentana={setVentana} datossimulacion={datossimulacion} setDatosSimulacion={setDatosSimulacion}   />;
   }


}

export default App;

