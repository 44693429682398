
import React from "react";
//import { render } from "@testing-library/react";

import '../styles/TrackingSimulado.css'
import logoHagemsa from "../img/hagemsa.png";

class TrackingSimulado extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            ordencompra: props.datossimulacion[0].oc,
            citem: props.datossimulacion[0].item,
            ncantidad: props.datossimulacion[0].cantidad,
            cnomprod: props.datossimulacion[0].descripcion,
            dfechaenvio: props.datossimulacion[0].fdespacho,
            dfecharecepcion:  props.datossimulacion[0].frecepcion,
            fdespacho: props.datossimulacion[0].fdespacho,
            dfecharecepcionmina: props.datossimulacion[0].fllegada,
            almacen: props.datossimulacion[0].almacen,
           
            recepcionado:'step completed2', 
            despachado: 'step completed1',
            transito: 'step completed1',
            recepcionadomina: 'step completed1',

        };

    }


    onRegresar = () => {
        this.props.setVentana(1);
    }







    render() {
        return (
            <div id="tablabusquedaform" className="main_container">
                <div><center>
                    <img src={logoHagemsa} alt='hagemsa' className='img-home' /></center><br />
                    <h4 className="titulo2"><center>HgTrack</center></h4>
                </div>
                <center>
                    <div id="button" className="row m-2" >
                        <button className="btn btn-danger" onClick={this.onRegresar} >Regresar</button>
                    </div>
                </center>

                <div className="container padding-bottom-3x mb-1">
                    <div><center><h3>Simulación</h3></center>  </div>
                    <div className="card mb-3"><hr />
                        <div className="row">

                            <div className="col-6 flex-derecha"><b>Orden Compra N°:</b></div>
                            <div className="col-6">{this.state.ordencompra}</div>
                        </div>
                        <div className="row">
                            <div className="col-6 flex-derecha"><b>Item:</b></div>
                            <div className="col-6">{this.state.citem}</div>
                        </div>
                        <div className="row">
                            <div className="col-6 flex-derecha"><b>Descripción:</b></div>
                            <div className="col-6">{this.state.cnomprod}</div>
                        </div>
                        <div className="row">
                            <div className="col-6 flex-derecha"><b>Cantidad:</b></div>
                            <div className="col-6">{this.state.ncantidad}</div>
                        </div>
                       



                        <hr />
                        <div className="card-body">
                            <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                                <div className={this.state.recepcionado}>
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-note2"></i></div>
                                    </div>
                                    <h4 className="step-title">Recepcionado ({this.state.almacen}) <br></br> Fecha {this.state.dfecharecepcion}</h4>
                                </div>

                                <div className={this.state.despachado} >
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-medal"></i></div>
                                    </div>
                                    <h4 className="step-title">Despachado<br /> Fecha: {this.state.fdespacho}</h4>
                                    
                                </div>
                                <div className={this.state.transito}>
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-car"></i></div>
                                    </div>
                                    <h4 className="step-title">Transito<br /> Fecha: {this.state.dfechaenvio}</h4>
                                </div>
                                <div className={this.state.recepcionadomina}>
                                    <div className="step-icon-wrap">
                                        <div className="step-icon"><i className="pe-7s-home"></i></div>
                                    </div>
                                    <h4 className="step-title">ETA Mina<br /> Fecha: {this.state.dfecharecepcionmina} </h4>
                                    <h4 className="step-title"><br /></h4>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div >





        )
    }
}
export default TrackingSimulado;