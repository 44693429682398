
import logoHagemsa from "../img/hagemsa.png";
import React from "react";
import '../styles/login.css';

class LoginForm extends React.Component {

  constructor(props) {
    super(props)

    this.verificaLogin = this.verificaLogin.bind(this)
    this.state = {
      usuario: '',
      clave: '',
      mensaje: '',
    }

  }

  verificaLogin = async () =>   {

    const tmpip =await fetch(process.env.REACT_APP_URL_API_EXTRANET + "fuenteextranet.php?p=dameip");
    const ip =await tmpip.json();
    let api="";
  // if(ip==="190.119.22.130") //190.119.55.130 143.0.251.144
  // {
  //  this.props.setLinkAdjuntos(process.env.REACT_APP_URL_API_EXTRANET) 
    // this.props.setLinkAdjuntos("http://localhost:8080/")
  // }
  // else{
    this.props.setLinkAdjuntos(process.env.REACT_APP_URL_HGAPI) 
  // }

   
    //verificacmo
    if (this.state.usuario === 'aaq' && this.state.clave === '1793') {
      
      this.props.setVentana(1);
    }
    else if(this.state.usuario === 'vurure' && this.state.clave === 'vurure'){

      this.props.setVentana(1);
    }
    else if(this.state.usuario === 'hgtest' && this.state.clave === 'hgtest'){

      this.props.setVentana(1);
    }
    else {
      this.setState({
       mensaje: '*** Datos incorrectos, ingrese nuevamente ***',
      })
    }
  }

  onValorchange = e => {
    if (e.target.id === 'usuario') {
      this.setState({
        usuario: e.target.value
      });
    }
    if (e.target.id === "clave") {
      this.setState({
        clave: e.target.value
      });
    }

    this.setState({
      mensaje: '',
     })

  }

  render() {

     
 /*  const sitiourl = window.location.href;
   if(sitiourl.includes("https"))
   {
    window.location.href = "http://seguimiento.hagemsa.org";
   }
 */
    
    let newDate = new Date();
    let anio = newDate.getFullYear();

    return (
      <div id="loginform">

        <FormLogo logo={logoHagemsa} />

        <Form onVerificaLogin={this.verificaLogin} onValorchange={this.onValorchange} />
        <Mensaje mensaje={this.state.mensaje} />
        <FormPie anio={anio} />
      </div>
    )
  }
}
export default LoginForm;



//componentes hijos

const Mensaje = props => (
  <div><center><span className="mensajeerror">{props.mensaje}</span></center></div>
);

const FormLogo = props => (
  <div><center>
    <img src={props.logo} alt='hagemsa' className='img-home' /></center>
  </div>
);



const FormPie = props => (
  <div>
    <h3 id="pieTitle">@Transportes Hagemsa SAC {props.anio} </h3>
  </div>
);

const Form = props => (
  <div>
    <h3 className="titulo"><center>HgTrack</center></h3>
    <hr/>
      <FormInput description='Usuario' className="form-control" id='usuario' placeholder="Ingrese Usuario" type="text" onValorchange={props.onValorchange} />
      <FormInput  description='Clave' id='clave' placeholder="Ingrese Clave" type="password" onValorchange={props.onValorchange} />
      <FormButton title="Ingrese" onVerificaLogin={props.onVerificaLogin} />
  </div>
);

const FormButton = props => (

  <div id="button" className="row ">
    
    <button className="btn btn-block btn-danger" onClick={props.onVerificaLogin}>{props.title}</button>
  </div>
);

const FormInput = props => (
  
  <div className="row mb-3">

    <label className="form-label">{props.description}</label>
      <input className="form-control" id={props.id} type={props.type} placeholder={props.placeholder} value={props.valor} onChange={props.onValorchange} />

  </div>  
);



